import { createStyles, makeStyles } from "@material-ui/core";
import { APP_BAR_HEIGHT } from "./navigation";

export const useStyles = makeStyles(theme =>
    createStyles({
        filledInput: {
            width: "100%",
            height: "46px",
            marginTop: theme.spacing(4)
        },
        spacer: {
            padding: theme.spacing(0, 2)
        },
        background: {
            width: "100%",
            height: "600px",
            background: theme.palette.primary.main,
            paddingTop: APP_BAR_HEIGHT + theme.spacing(4),
            paddingLeft: theme.spacing(4),
            paddingRight: theme.spacing(4)
        },
        summaryText: {
            fontStyle: "normal",
            fontWeight: 500,
            marginLeft: 15,
            fontSize: 32
        },
        card: {
            width: 500,
            height: 150,
            background: "linear-gradient(to right, #00bfff, #007FFF)",
            boxShadow: theme.shadows[8],
            color: "#FFFFFF",
            padding: 10
        },
        grid: {
            paddingTop: "50px",
            lineHeight: "145%",
            display: "flex",
            color: "#FFFFFF"
        },
        loginCard: {
            top: "100px",
            minWidth: 420,
            maxWidth: 420,
            padding: 16,
            backgroundColor: "#F7FAFC",
            boxShadow: "0px 0px 32px rgba(136, 152, 170, 0.15)",
            borderRadius: "6px"
        },
        tabsGrid: {
            width: "inherit",
            height: 50,
            marginBottom: theme.spacing(4)
        },
        tabs: {
            fontSize: 12,
            width: 172,
            fontWeight: 600,
            boxShadow: "0px 4px 6px rgba(50, 50, 93, 0.11), 0px 1px 3px rgba(0, 0, 0, 0.08)",
            color: "#212529"
        },
        tabsList: {
            maxWidth: 1400,
            backgroundColor: "#F7FAFC",
            borderRadius: 5
        }
    })
);
