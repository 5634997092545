import { GridViewHeadlineIcon } from "@material-ui/data-grid";
import { DirectionsRun } from "@material-ui/icons";
import React from "react";
import { Route, UserRoute } from "@onpreo/components";

const routes = [{ divider: true }, { name: "Nutzer", path: "/dashboard", icon: <GridViewHeadlineIcon /> }] as Route[];

export const userRoutes = [{ name: "Logout", path: "/logout", icon: DirectionsRun }] as UserRoute[];

export default routes;
