import Grid from "@material-ui/core/Grid";
import React, { FC } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { FilledInput, InputAdornment } from "@material-ui/core";
import { Card, Button, CardContent, Typography } from "@material-ui/core";
import PersonIcon from "@material-ui/icons/Person";
import Lock from "@material-ui/icons/Lock";
import IndexContainer from "../index-container";
import { useRouter } from "next/router";
import { useStyles } from "../styles";
import { signIn, useSession } from "next-auth/react";

const errors = {
    default: "Falsch Benutzername oder Passwort"
};

interface Error {
    error: string | string[];
}

const SignInError: FC<Error> = ({ error }) => {
    const errorMessage = error && errors.default;
    return <div>{errorMessage}</div>;
};

const Login = () => {
    const classes = useStyles();
    const router = useRouter();
    const { error } = useRouter().query;
    const { data: session, status } = useSession();

    const {
        control,
        handleSubmit,
        formState: { errors }
    } = useForm({ defaultValues: { username: "", password: "" } });

    const handleLogin: SubmitHandler<{ username: string; password: string }> = async (data: any) => {
        const result = await signIn("credentials", { ...data, redirect: false });
        if (result?.status === 200) {
            if (session?.user && status === "authenticated") await router.push("/dashboard");
        } else {
            await router.push(`/?error=${error}`);
        }
    };

    return (
        <IndexContainer>
            <Grid justifyContent={"center"} className={classes.grid}>
                <Typography variant={"h1"} style={{ color: "#525f7f", fontWeight: 500 }}>
                    Willkommen bei onpreo!
                </Typography>
            </Grid>
            <Grid justifyContent={"center"} style={{ paddingTop: "30px", display: "flex" }}>
                <Typography variant={"h3"} style={{ color: "#525f7f", fontWeight: 400 }}>
                    Nutze das unten angezeigte Formular um dich direkt einzuloggen.
                </Typography>
            </Grid>
            <Grid container xs={12} justify="center">
                <Card className={classes.loginCard}>
                    <CardContent>
                        <Grid container justifyContent={"center"}>
                            <Typography>Loggen Sie sich mit Ihren Nutzerdaten ein</Typography>
                        </Grid>
                    </CardContent>
                    <form>
                        <div className={classes.spacer}>
                            <Grid container justifyContent={"center"} alignItems={"center"} direction={"column"}>
                                <Grid item style={{ width: "100%" }}>
                                    <Controller
                                        control={control}
                                        name="username"
                                        render={({ field }) => (
                                            <FilledInput
                                                {...field}
                                                className={classes.filledInput}
                                                placeholder="Name"
                                                autoComplete="off"
                                                required
                                                type="text"
                                                error={errors.username ? true : false}
                                                startAdornment={
                                                    <InputAdornment position="start">
                                                        <PersonIcon />
                                                    </InputAdornment>
                                                }
                                            />
                                        )}
                                    />
                                </Grid>
                            </Grid>
                        </div>
                        <div className={classes.spacer}>
                            <Grid container justifyContent={"center"} alignItems={"center"} direction={"column"}>
                                <Grid item style={{ width: "100%" }}>
                                    <Controller
                                        control={control}
                                        name="password"
                                        render={({ field }) => (
                                            <FilledInput
                                                {...field}
                                                className={classes.filledInput}
                                                placeholder="Passwort"
                                                autoComplete="off"
                                                required
                                                type="password"
                                                error={errors.password ? true : false}
                                                startAdornment={
                                                    <InputAdornment position="start">
                                                        <Lock />
                                                    </InputAdornment>
                                                }
                                            />
                                        )}
                                    />
                                </Grid>
                            </Grid>
                        </div>
                        <div className={classes.spacer}>
                            <Grid container justifyContent={"center"} style={{ marginTop: 50 }}>
                                <Button type="submit" variant="contained" color={"primary"} onClick={handleSubmit(handleLogin)}>
                                    Anmelden
                                </Button>
                            </Grid>
                        </div>
                        <Typography variant="inherit" align={"center"} color="error">
                            {error && <SignInError error={error} />}
                        </Typography>
                    </form>
                </Card>
            </Grid>
        </IndexContainer>
    );
};

export default Login;
