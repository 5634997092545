import React, { FC, Fragment } from "react";
import { Sidebar, AdminNavbar } from "@onpreo/components";
import routes, { userRoutes } from "./routes";
import { makeStyles, createStyles } from "@material-ui/core";

export const SIDEBAR_OPEN_WIDTH = 250;
export const APP_BAR_HEIGHT = 76;

const useStyles = makeStyles(theme =>
    createStyles({
        container: {
            [theme.breakpoints.up("xl")]: {
                marginLeft: SIDEBAR_OPEN_WIDTH
            }
        }
    })
);

const Navigation: FC = props => {
    const classes = useStyles();
    return (
        <Fragment>
            <AdminNavbar userRoutes={userRoutes} user={{ name: "Noah Frohn", imgAlt: "..." }} />
            <Sidebar
                closeSidebarResponsive={() => console.log("hi there")}
                openResponsive={true}
                routes={routes}
                logo={{ imgSrc: "../logo.png", imgAlt: "onpreo", innerLink: "./" }}
                user={{ name: "Noah Frohn", imgAlt: "..." }}
            />
            <div id={"app-content"} className={classes.container}>
                {props.children}
            </div>
        </Fragment>
    );
};

export default Navigation;
