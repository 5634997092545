import React, { FC } from "react";
import { Box } from "@material-ui/core";
import { IndexNavbar, StaticFooter } from "@onpreo/components";

const IndexContainer: FC = props => {
    return (
        <Box
            style={{
                height: "100vh",
                backgroundColor: "#273340"
            }}
        >
            <IndexNavbar logo={{ imgSrc: "/logo.png", imgAlt: "onpreo" }} />
            <Box
                style={{
                    height: "428.41px",
                    background: "linear-gradient(90deg, #D5D5D5 0%, #D5D5D5 99.99%, #273340 100%)"
                }}
            >
                {props.children}
            </Box>
            <StaticFooter dark={true} />
        </Box>
    );
};

export default IndexContainer;
